.ladiui.icon-menu-item1 {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg) no-repeat 50% 50%;
}

.ladiui.icon-menu-item2 {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg) no-repeat 50% 50%;
}

.ladiui.icon-menu-item3 {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
}

.ladiui.icon-menu-item4 {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg) no-repeat 50% 50%;
}

.ladiui.icon-ldp.icon-copy{
    position: absolute;
    background-color: var(--main-default);
    box-sizing: unset;
    width: 14px;
    height: 14px;
    margin-left: -10px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladishare/copy-20808.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladishare/copy-20808.svg) no-repeat 50% 50%;
}

.ladiui.icon-ldp.icon-refresh{
    position: absolute;
    background-color: var(--main-default);
    box-sizing: unset;
    width: 14px;
    height: 14px;
    margin-left: -10px;
    display: inline-block;
    mask-size: cover !important;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-refresh.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-refresh.svg) no-repeat 50% 50%;
}